// $pryColor: #050301;
// $pryColorVariant: #121212;
// $secColor: #e9d402de;
// $buttonColor: #16a434;
// // text colors
// $bodyTextColor: rgba(255, 255, 255, 0.7);
// $mutedTextColor: rgba(255, 255, 255, 0.5);
// // $warningTextColor: #e9d402de;
// $errorTextColor: red;
// $successTextColor: green;

// // text color in card
// .inlineTextColor {
// 	color: $secColor;
// }

$pryColor: #ec6500;
$secColor: #16a434;
$whiteColor: #fff;
