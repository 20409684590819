@use "./generalStyles/reset";
@use "./generalStyles/mediaqueries" as *;

// components
@use "./componentStyles/navbarStyles";
@use "./componentStyles/buttonStyles";
@use "./componentStyles/cardStyles";
@use "./componentStyles/formStyles";
@use "./componentStyles/footerStyles";
@use "./componentStyles/socialmediaIcons";

// pages
// @use "./pageStyles/homepageStyles";

.HomeBg {
	background-image: url("../images/heroImages/homepage_hero_bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: scroll;
	min-height: 100vh;
	min-width: 100vw;
}

@media (max-width: $phone) {
	.HomeBg {
		background-color: rgba(212, 209, 209, 0.5);
		// background-color: rgb(0, 0, 0);
		background-position: right -27rem bottom 2rem;
		// min-height: 100%;
		// color: #fff;
	}
}

@media (min-width: $tablet) {
	.HomeBg {
		background-position: center;
		background-position: center -5rem;
	}
}
