@use "./colors" as *;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
	// background-color: $pryColor !important;
	// overflow: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
}
